import { transparentize } from "polished"

export type ThemeType = typeof LightTheme

const _lightBaseTextColor = '#444';
const _darkBaseTextColor  = '#FFF';

export const LightTheme = {
  size: {
    spacingUnit: '4px',
  },
  color: {
    text: {
      primary:   _lightBaseTextColor,
      secondary: transparentize(0.3, _lightBaseTextColor),
      tertiary:  transparentize(0.6, _lightBaseTextColor),
      disabled:  transparentize(0.5, '#777'),
    },
    border:      transparentize(0.88, _lightBaseTextColor),
    background:  '#FFF',
  },
  typography: {
    fontSize: {
      base:    '16px',
      h1:      '2.0rem',
      h2:      '1.75rem',
      h3:      '1.53rem',
      h4:      '1.34rem',
      h5:      '1.17rem',
      h6:      '1.08rem',
      body1:   '1.0rem',
      body2:   '0.875rem',
      caption: '0.75rem',
    },
  },
}

export const DarkTheme: ThemeType = {
  ...LightTheme,
  color: {
    text: {
      primary:   transparentize(0.05, _darkBaseTextColor),
      secondary: transparentize(0.45, _darkBaseTextColor),
      tertiary:  transparentize(0.67, _darkBaseTextColor),
      disabled:  transparentize(0.75, _darkBaseTextColor),
    },
    border:      transparentize(0.88, _darkBaseTextColor),
    background:  '#111',
  },
}

export const DefaultTheme = LightTheme
