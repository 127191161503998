import React from "react"
import type { GatsbyBrowser } from "gatsby"
import { ThemeProvider } from "styled-components"
import { DefaultTheme } from "./src/styles/Theme"
import { GlobalStyle } from "./src/styles/GlobalStyle"
import "normalize.css/normalize.css"
import "./src/styles/webfonts.css"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <GlobalStyle />
      {element}
    </ThemeProvider>
  )
}
