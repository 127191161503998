import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    touch-action: manipulation;
    -webkit-touch-callout: none;
  }

  html {
    height: 100%;
    margin: 0;
    background-color: ${props => props.theme.color.background};
    color: ${props => props.theme.color.text.primary};
    font-family: 'Open Sans', sans-serif;
    font-size: ${props => props.theme.typography.fontSize.base};
    scroll-behavior: smooth;
    overscroll-behavior-y: none;
  }

  body {
    height: 100%;
    margin: 0;
    color: ${props => props.theme.color.text.primary};
    text-size-adjust: 100%;
    overscroll-behavior-y: none;
  }

  // gatsby
  #___gatsby {
    height: 100%;
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`
